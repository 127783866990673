export type User = {
  id: string;
  email: string;
  industries: string[];
  locations: string[];
  firstName: string;
  profileImage: string;
  lastName: string;
  role: UserRoles;
  stripeCustomerId: string;
  company: Company;
  companyId: string;
  appViewEnabled?: boolean;
  pdfAskEnabled?: boolean;
};

export type InterviewInvitationInfo = {
  recruiterName: string;
  stageDuration: number;
  companyName: string;
  roomId: string;
  applicationId: string;
  sessionTitle: string;
};

export type NotificationButton = {
  data: any;
  name: string;
  type: 'accept' | 'decline';
};

export type Notification = {
  id: string;
  email: string;
  title: string;
  description: string;
  notificationType: string;
  buttons: NotificationButton[];
  lastName: string;
  role: UserRoles;
  companyId: string;
};

export enum UserRoles {
  COMPANY_ADMIN = 'company_admin',
  RECRUITER = 'recruiter',
  INDIVIDUAL = 'individual',
}

export type Company = {
  _id: string;
  email: string;
  name: string;
  culture: string;
  industries: string[];
  locations: string[];
  country: string;
  description: string;
  brandColor: string;
  websiteLink: string;
  ownerId: string;
  profileImage: string;
  boardDomain: string;
  accountType: string;
  availableInterviews: number;
};

export type InviteInfo = {
  urlToken: string;
  company: Company;
  invitedEmail: string;
};

export interface Presentation {
  slide_titles: string[];
  slide_paragraphs: SlideParagraph[];
  bullet_points: string[][];
  sources: any;
  images: Image[];
}

export interface SlideParagraph {
  sources: string[];
  text: string;
}

export interface Image {
  image_id: string;
  image_url: string[];
  blur_hash: string;
}

export type MindConfig = {
  questions: { text: string }[];
  scenarios: { text: string }[];
  intro: string;
  voice: string;
  language: string;
  notes: string;
  model: string;
  conversationType: string;
  presentation: Presentation;
};

export type Template = {
  _id: string;
  id: string;
  companyId: string;
  publicId: string;
  location?: string;
  plainText: {
    jd: string;
  };
  sessionType: string;
  visibility: 'open' | 'closed' | 'draft';
  name: string;
  jd: string;
  ownerId: string;
  lastPublishedDate?: string;
  mindConfig: MindConfig;
};

export type Vacancy = {
  id: string;
  _id: string;
  name: string;
  jobType: string;
  jd: IJd;
  lastPublishedDate: string;
  mindConfig: MindConfig;
  publicId: string;
  companyId: string;
  created_at_timestamp: number;
  companyInfo: ICompanyInfo;
};
export type IJd = {
  type: string;
  content: any[];
};

export type ICompanyInfo = {
  name: string;
  profileImage: string;
  accountType: string;
  publicId: string;
};

export type Application = {
  firstName: string;
  id: string;
  lastName: string;
  templateId: string;
  created_at_timestamp: Date;
  updated_at_timestamp: Date;
  email: string;
  fileUrl: string;
  status: string;
  roomId?: string;
  summaryId?: string;
};

export type ApplicationWithTemplate = {
  template: IDocument<Template>;
  application: IDocument<Application>;
};

export type IDocument<T> = T & { id: string };

export type Driver = {
  id: string;
};

export type Stage = {
  _id: string;
  name: string;
  enabled: boolean;
  duration: number;
};

export type RoomTemplate = {
  ownerId: string;
  drivers: Driver[];
  name: string;
  stages: Stage[];
};

export type Room = {
  application: Application;
  applicant: {
    firstName: string;
    lastName: string;
    email: string;
  };
  templateId: string;
  email: string;
  status: string;
  data: { fileUrl: string };
  sessionTitle: string;
  name: string;
  profileImage: string;
  roomId: string;
  state: 'upcoming' | 'idle' | 'started' | 'ended' | 'last-stage-finished';
  startTimestamp: number;
  startedAt?: number;
  company: {
    name: string;
    profileImage: string;
  };
};

export type ChecklistItem = {
  id: string;
  isChecked: boolean;
  text: string;
};

export type CompanionState = {
  isReady: boolean;
  isListening: boolean;
  isSpaceBarPressed: boolean;
  isUserSpeaking: boolean;
  isSpeaking: boolean;
  isConnected: boolean;
  isVideoHidden: boolean;
  stop: () => void;
  start: () => void;
  toggleVideo?: (visibility: boolean) => void;
  requestLeave: () => void;
  userHolds: (isHolding: boolean) => void;
  setHumeSettings?: ({
    system_prompt,
    initial_assistant_prompt,
    chat_group_id,
  }: {
    system_prompt: string;
    initial_assistant_prompt?: string;
    chat_group_id?: string;
  }) => void;
  sendAssistantInput?: (message: string) => void;
  sendUserInput?: (message: string) => void;
  isMuted: boolean;
  isFullscreen: boolean;
  toggleFullscreen: (value: boolean) => void;
  toggleMute: (value: boolean) => void;
};
