import { useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect, useRef } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import {
  authUser,
  authInitializeTracker,
  authenticatingState,
} from './state/auth';
import { getAuthDeferred } from '@/utils/firebase';
import api from './api';
import { authCompany } from './state/company';
import { LoadingOverlay } from '@mantine/core';
import { toast } from 'react-hot-toast';
import { navigate } from 'vike/client/router';
import { getDefaultURLFromUserRole } from './utils/user';
import useUser from './hooks/useUser';

function AuthLayer({ children }) {
  const setAuthUser = useSetRecoilState(authUser);
  const [company, setAuthCompany] = useRecoilState(authCompany);
  const [initialized, setAuthInitialize] = useRecoilState(
    authInitializeTracker,
  );
  const isCreatingAccountFromGoogle = useRef(false);
  const { createCompanyAccount } = useUser();

  const [authenticating, setAuthenticating] =
    useRecoilState(authenticatingState);
  useEffect(() => {
    if (authenticating) {
      toast.loading('Signing in...', {
        id: 'auth-status',
        duration: Infinity,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          zIndex: 9999,
        },
      });
    } else {
      toast.dismiss('auth-status');
    }

    return () => {
      toast.dismiss('auth-status');
    };
  }, [authenticating]);

  async function retrieveUser(
    token,
    isProviderGoogle,
    { email, firstName, lastName },
  ) {
    try {
      const userDetails = await api.getUser(token);

      if (userDetails) {
        setAuthUser(userDetails);
        setAuthCompany(userDetails.company);
      }

      return userDetails;
    } catch (e) {
      if (
        e.response &&
        e.response.data.message === 'user-not-found' &&
        isProviderGoogle
      ) {
        if (isCreatingAccountFromGoogle.current) {
          return;
        }
        setAuthInitialize(false);

        const auth = getAuthDeferred();

        // e.response message is from the server
        // We check e.response we might get message from server too
        isCreatingAccountFromGoogle.current = true;
        createCompanyAccount(
          auth.currentUser.email,
          '',
          {
            email: auth.currentUser.email,
            accountType: 'company',
            name: auth.currentUser.displayName,
            firstName: auth.currentUser.displayName?.split(' ')[0],
            lastName: auth.currentUser.displayName?.split(' ')[1] || '',
            profileImage: auth.currentUser.photoURL,
          },
          {
            onSuccess: () => {
              isCreatingAccountFromGoogle.current = false;
              setAuthInitialize(true);
            },
            onError: (e) => {
              isCreatingAccountFromGoogle.current = false;
              console.log('error', e);
            },
          },
        );
        return;
      }
      throw e;
    }
  }

  useEffect(() => {
    const unlisten = onAuthStateChanged(getAuthDeferred(), async (user) => {
      const locationPathname = window.location.pathname;
      if (user) {
        try {
          const token = await user?.getIdToken();
          const fetchedUser = await retrieveUser(
            token,
            user.providerData[0].providerId === 'google.com',
            {
              email: user.email,
              firstName: user.displayName?.split(' ')[0],
              // TODO: Fix this
              lastName: user.displayName?.split(' ')[1] || '',
            },
          );
          if (locationPathname === '/' || locationPathname === '/register') {
            console.log('navigating to the thing');
            await navigate(getDefaultURLFromUserRole(fetchedUser?.role));
            setAuthInitialize(true);
          } else {
            setAuthInitialize(true);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        if (locationPathname !== '/register') {
          navigate('/');
        }
        setAuthInitialize(true);
        setAuthUser(null);
      }
      setAuthenticating(false);
    });

    return () => {
      unlisten();
      setAuthInitialize(false);
      setAuthenticating(false);
    };
  }, [setAuthUser, setAuthInitialize]);

  return <>{!initialized ? <LoadingOverlay visible /> : children}</>;
}

export default AuthLayer;
