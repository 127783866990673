export default function invariant(expr: any, msg: string): asserts expr {
  if (!expr) {
    throw new Error(msg);
  }
}

export function addProtocol(url) {
  if (!url) return;
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }
  return url;
}

export const tags = (() => {
  let title = 'GreetAI';
  let description = '';
  let keywords = '';

  title = 'GreetAI — Screen candidates faster';
  description =
    'Evaluate applicants and ensure faster time-to-hire with AI which sounds just like a human';
  keywords =
    'ai screening,ats tool,applicant tracking system,candidate screening,recruitment automation,hiring software,ai recruitment,talent acquisition,resume screening,hiring process,recruitment tool,ai hiring,job application software,automated hiring,recruitment platform,ai talent screening,ai hiring tool,recruitment management,ai candidate evaluation';

  return {
    title,
    keywords,
    description,
  };
})();
